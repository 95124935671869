import {
  Block,
  Button,
  Container,
  Grid,
  Text,
} from '@actovos-consulting-group/ui-core';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Hero = () => {
  return (
    <Block py={40}>
      <Container>
        <Grid.Row>
          <Grid.Column size={{ _: 12, xs: 8 }}>
            <Block>
              <Text as="h1" fontSize={{ _: 30, xs: 50, sm: 70 }}>
                Success Through{' '}
                <Text display="inline" color="primary">
                  Actionable
                </Text>{' '}
                Feedback
              </Text>
              <Text as="p">
                We&lsquo;re a group of engineers, analysts, founders, investors,
                devops, and more, who want you to succeed. With success comes
                hard truths. We will provide you with honest feedback,
                competitive research, and occasionally introduce you to our
                network.
              </Text>
              <Button
                as={Link}
                to="/submit"
                size="xl"
                style={{ textDecoration: 'none' }}
              >
                Submit a Product
              </Button>
            </Block>
          </Grid.Column>
          <Grid.Column size={{ _: 12, xs: 4 }}>
            <Block>
              <StaticImage
                src="../../images/hero_person.jpg"
                alt="Hero"
                placeholder="tracedSVG"
              />
            </Block>
          </Grid.Column>
        </Grid.Row>
      </Container>
    </Block>
  );
};

export default Hero;
