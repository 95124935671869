import {
  Block,
  Container,
  Grid,
  Text,
} from '@actovos-consulting-group/ui-core';
import React from 'react';
import ProductCard from '../../components/product-card';

const RecentCompanies = ({ reviews }) => {
  return (
    <Container>
      <Block my={5}>
        <Text textAlign="center" mb={4} fontSize={30}>
          Recently Reviewed
        </Text>
        <Grid.Row>
          {reviews.map(product => (
            <Grid.Column key={product.id} size={{ _: 12, xs: 6 }}>
              <ProductCard product={product} />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Block>
    </Container>
  );
};

export default RecentCompanies;
