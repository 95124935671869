import { Block, Container, Text } from '@actovos-consulting-group/ui-core';
import React from 'react';
import ProductCard from '../../components/product-card';

const SpotlightCompany = ({ spotlight }) => {
  return (
    <Block bg="#F9F9F9" py={20} mt={60}>
      <Container>
        <Text
          textAlign="center"
          fontSize={{ _: 30, sm: 50, md: 70 }}
          as="h2"
          mt={0}
          mb={40}
        >
          In the Spotlight
        </Text>
        <ProductCard product={spotlight} />
      </Container>
    </Block>
  );
};

export default SpotlightCompany;
