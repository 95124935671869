import {
  Button,
  Container,
  Flex,
  Text,
} from '@actovos-consulting-group/ui-core';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  border-top: 1px solid ${p => p.theme.colors.mutedGrey};
`;

const RoastedCTA = () => {
  return (
    <Wrapper p={5}>
      <Container>
        <Text textAlign="center" as="h2" fontSize={40}>
          Want to get Roasted or Toasted?
        </Text>
        <Flex alignItems="center" justifyContent="center">
          <Button as={Link} to="/submit" style={{ textDecoration: 'none' }}>
            Submit a Product
          </Button>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default RoastedCTA;
