import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../sections/hero';
import RecentCompanies from '../sections/recent-companies';
import RoastedCTA from '../sections/roasted-cta';
import SpotLight from '../sections/spotlight';

export const query = graphql`
  fragment ProductFields on ProductConnection {
    nodes {
      id
      slug
      name
      score
      industries
      description
      remoteImage {
        childImageSharp {
          gatsbyImageData(width: 235, placeholder: BLURRED, height: 235)
        }
      }
    }
  }
  query roastQueryAndToastQuery {
    spotlightQuery: spotlight {
      id
      slug
      name
      score
      industries
      description
      remoteImage {
        childImageSharp {
          gatsbyImageData(width: 235, placeholder: BLURRED, height: 235)
        }
      }
    }
    reviewsQuery: allProduct(
      limit: 10
      sort: { fields: reviewedAt, order: DESC }
      filter: { score: { gt: 0 } }
    ) {
      ...ProductFields
    }
  }
`;

const Home = ({
  data: {
    reviewsQuery: { nodes: reviews },
    spotlightQuery: spotlight,
  },
}) => (
  <Layout>
    <SEO title="Success Through Actionable Feedback" />
    <Hero />
    <SpotLight spotlight={spotlight} />
    <RecentCompanies reviews={reviews} />
    <RoastedCTA />
  </Layout>
);

export default Home;
